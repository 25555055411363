


 .creator .profile {
    height: 130px;
    width:130px;
    /* border: 3px solid #f1f1f1; */
    border-radius: 50%;
    position: relative;
  }
  .creator .profile img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
 

  .creator .editcamera {
    width: 32px;
    height: 32px;
    position: absolute;
   right: -3px;
   z-index: 0;
   background: #D8D8D8;
   border-radius: 50%;
   bottom: 25px;
   cursor: pointer;
  }
  .creator .cameracircle{
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .creator .editcamera img{
    margin: 0 auto;
width: 20px;
height: auto;
border-radius: unset;
position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
}
 .creator .userbio{
   /* display: inline-block;
   margin-left: 10px; */
   margin: 0 auto;
   position: relative;
   width: 180px;
   text-align: center;
 }
 .creator .username{
   font-size: 20px;
   font-weight: 400;
   color: #4F4F4F;
 }
.creator .title{
  font-weight: 300;
  font-size: 14px;
  color: #6FCF97;
}
/* .green--Text {
    color: var(--greenText);
    font-family:  'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  } */

  
  .creator .edit {
   width:12px;
   height: 12px;
   margin-left: 4px;
   cursor: pointer;
  }
  
/* @media screen and (max-width: 768px) {
  .pen {
    left:410px;
  }
} */


@media screen and (max-width: 2000px) {
  .creator .editbanner{
    top:80px;
    position: absolute;
    right: 10px;
    background: #D8D8D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4F4F4F, 100%;
    padding: 5px;
    cursor: pointer;
  }
  .creator .editbanner img{
    width: 20px;
    display: inline;
    margin-right: 5px;
    cursor: pointer;
  }

}

@media screen and (max-width: 991px) {
.creator .editbanner{
  top: -123px;
  right: 0px;
  padding: 7px;
  border-radius: 50%;
  padding-bottom: 9px;
}
.creator .cameratext{
display: none;
}
.creator .editbanner img{
  
  display: inline;
  margin-right: 0px;
  cursor: pointer;
}
}

/* .creator .editbanner{
  top:80px;
  position: absolute;
  right: 10px;
  background: #D8D8D8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #4F4F4F, 100%;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .creator .editbanner{
    top:-280px;
    position: relative;
    background: #D8D8D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4F4F4F, 100%;
    padding: 5px;
    cursor: pointer;
    left: 60%;
    width: 40%;
}
}

.creator .editbanner img{
  width: 20px;
  display: inline;
  margin-right: 5px;
  cursor: pointer;
} */

.creator .profile  {
 margin: 0 auto;
}

.description{
 font-size: 15px;
 font-family: Roboto;
}

.destitle{
  color: #aaa5a5;
  font-size: 14px;
  font-family: Roboto;
 }