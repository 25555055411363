@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 12px !important;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  height: 45px !important;
  margin-left: 0;
  background: #ffffff;
  border: none !important;
  border-bottom: 2px solid var(--greyLight) !important;
  border-radius: 0px !important;
  line-height: 25px;
  height: 35px;
  width: 285px !important;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.linkhover:hover {
  cursor: pointer;
  color: blue;
}
.onboarding-box {
  padding: 50px;
  box-shadow: 0px 2px 28px 4px #e2e2e2ab;
  border-radius: 15px;
}
.onboarding-container {
  /* background-color: red; */
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: white !important;
  margin: 3px !important;
  border: 2px solid white !important;
  border-right: 0px white !important;
  border-radius: 3px 0 0 3px;
}

:root {
  --black: #000000;
  --white: #ffff;
  /* // googlePlus: '#DD4B39'
    // facebook: '#3b5998',
    // twitter: '#1DA1F2',*/
  --error: rgba(200, 0, 0, 0.8);
  --greybg: #f2f2f2;
  --accent: #aacfff;
  --bg: #429bee;
  --heading1: #4a4a4a;
  --greyLight: #f1f1f1;
  --greenText: #6fcf97;
  --darkGrey: #828282;
  --buttongrey: #979797;
  --accentfaint: #5594e0;
  /* Main Colors */
}

.horizontal {
  padding: 15px;
  width: 315px;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.horizontalCenter {
  align-items: 'center';
  width: '100%';
}

.tabcontainer {
  margin-bottom: 15px;
}

.phoneinput {
  /* align-items: 'left';
  width: 19%;
  display: inline-block;
  float:left; */
}

.phoneinput .form-control {
  height: 45px;
  margin-top: 1px;
  border: 1px solid #f1f1f1;
  border: 1px solid var(--greyLight);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(73, 80, 87);
  mix-blend-mode: normal;
  opacity: 0.8;
}

.textinputcontainer {
  margin-left: 2px;

  width: 80%;
  display: inline-block;
}
.textinputcontainer .form-control,
.textinputcontainer .form-group {
  display: inline-block;
  width: 100%;
}
.react-tel-input .boxcon {
  height: 48px;
}

/* .img-m {
  margin-top: 56px;
} */
.pointer {
  cursor: pointer;
}

.signuptip {
  text-align: center;
  margin-top: 2px;
}

body {
  font-family: 'Roboto';
}

.signin {
  width: 60px;
  height: 27px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #6fcf97;
  color: var(--greenText);
}

.accent {
  color: #aacfff;
  color: var(--accent);
}

.whiteBG {
  background-color: #ffff;
  background-color: var(--white);
}

.greyBG {
  background-color: #f2f2f2;
  background-color: var(--greybg);
}

.positionCenter {
  position: absolute;
  align-self: center;
}

.pageTitle {
  color: #4a4a4a;
  color: var(--heading1);
  font-size: 16;
  font-family: 'Roboto', sans-serif;
}

.greenText {
  color: #6fcf97;
  color: var(--greenText);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.greyText {
  color: #828282;
  color: var(--darkGrey);
  font-size: 16;
  font-family: 'Roboto', sans-serif;
}

.buttonContainer {
  width: '80%';
}

.rowSpaceBetween {
  flex-direction: row;
  justify-content: space-between;
}

.TextalignCenter {
  text-align: center;
}

.appName {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.innerContainer {
  width: '75%';
}

.smallContainer {
  /* marginVertical: moderateScale(3); */
  border-radius: 8;
  width: width-8;
  align-self: 'center';
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  box-shadow: initial;
}

.btn-primary:hover {
  color: #fff;
  background-color: unset;
  border-color: currentColor;
  border-color: initial;
}
.btn:focus {
  outline: 0;
  box-shadow: #fff !important;
}

.btn-primary:focus {
  color: #fff;
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
  box-shadow: initial;
}

/* .customShadow {
  elevation:8
} */

/* .light14 {
  font-size:Fonts.size.h2;
  color: Colors.darkGrey;
  /* letterSpacing: 0.1;
  fontFamily:'Roboto-Light', 
} */

/* .smallContainerPadding{
  paddingVertical:moderateScale(20);
  paddingHorizontal: moderateScale(14);
}, */

/* mh5: {marginLeft: Metrics.horizontal.scale5},
  mv5: {marginVertical: Metrics.vertical.scale5},
  mv12: {marginVertical: Metrics.vertical.scale12},
  mv10: {marginVertical: Metrics.vertical.scale10},
  mv40: {marginVertical: Metrics.vertical.scale40},
  mt30: {marginTop: Metrics.vertical.scale30}, 
  mt60: {marginTop: Metrics.vertical.scale60},  */
/* .btn-primary.focus, .btn-primary:focus,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled):active:focus{
    outline: none;
    box-shadow: unset;
    background-color: initial;
  } */

.uploadtext{
    z-index: 2500;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  .timer{
    display: none;
  }

.sharesomethingcard {
  width: 99%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow:0px 0px 5px 1px rgb(252 169 173 / 85%);
  position: relative;
  height: 110px;
  cursor: pointer;
}

.sharesomethingcard .sharesomethingcontent{
  margin: 15px;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.sharesomethingcard .dpcircle img{
 width: 100%;
 height: 100%;
 cursor: pointer;
}
.sharesomethingcard .dpcircle{
  position: absolute;;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  margin-top: -10px;

}
.sharesomethingcard .postform{
  margin-left: 60px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  text-align: left;
  margin-top: -10px;
  cursor: pointer;
}

.sharesomethingcard .privacyselect{

  margin-top: -20px;
}
.sharesomethingcard .postform .privacyselecttext{
  font-weight: 300;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

.sharesomethingcard .postform .posttext{
  color: #828282;
  font-weight: 300;
  margin-top: 20px;
  font-size: 14px;
}
/* .sharesomethingcard .postform {
width: 98%;
}
 */
.sharesomethingcard .posttext{
  top: 45px;
    position: absolute;
  left: 60px;
}
.sharesomethingcard .shareform,.sharesomethingcard textarea.form-control, .sharesomethingcard .form-group{
  height: 100%;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 10px;
}

.sharesomethingcard .sharetextbox{
  border: 0px;
  resize:none;
}

@media screen and (max-width: 700px) {

.sharesomethingcard .postform {

  width: 80% !important;
}
}
.form-control:focus {
  border-color: var(--bg) !important;
  box-shadow: none !important;
  margin: none !important;
}

.input {
  width: 100%;
  height: 49px;
  border: 1px solid var(--greyLight);
  border-width: 0px 0px 2px 0px;

  outline: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.commentinput {
  width: 70%;
  border-radius: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  /* opacity: 0.8; */
  margin-left: 40px;
}

.form-group {
  margin-bottom: 15px !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.invalid-feedback {
  font-family: 'Roboto', sans-serif !important;
  display: none;
  width: 100%;
  font-weight: 300;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--red);
}
/* .margin {
  margin-bottom: 15px;
} */

.passwordIcon {
  position: absolute;
  /* margin-left: 104px; */
  margin-top: -35px;
  width: 20px;
  right: 40px;
}

.searchicon svg {
  position: absolute;
  top: 15px;
  right: 40px;
  cursor: pointer;
}
.form-control {
  border-radius: 0 !important;
}
.amountdollar {
  position: relative;
  z-index: 20;
  margin: 0 !important;
  left: -2px;
  bottom: -36px;
  font-size: 15px;
}
@media screen and (max-width: 991px) {
  .searchicon svg {
    position: absolute;
    top: 30px;
    /* margin-left: 39%; */
  }
}

@media screen and (max-width: 350px) {
  .commentinput {
    width: 60%;
    padding: 0 10px;
  }
}

@charset "UTF-8";
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap'); */

/*!
 =========================================================
 * ArchitectUI ReactJS Dashboard Theme - v1.0.0
 =========================================================
 * Product Page: https://dashboardpack.com
 * Copyright 2019 DashboardPack (https://dashboardpack.com)
 * Licensed under MIT (https://github.com/DashboardPack/architectui-react-theme-free/blob/master/LICENSE)
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */


/* .buttonSmall {
    height:moderateScale(30),
    width:moderateScale(80)
} */







.buttonmain {
  /* height:moderateScale(50),*/

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  border-radius: 15px ;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 48px;
  margin: 20px 0;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  
}
.button1 {
  background-color: var(--bg) ;
  color: var(--white);
  box-shadow: 2;
}
.button1:hover,
.button1:focus,
.button1:active {
  background-color: var(--bg) ;
  outline: none ;
  box-shadow: none ;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  /* transform: translateY(1px);  */
}

.button2 {
  background-color: var(--darkGrey);
  color: var(--white);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
}
.button2:hover,
.button2:focus,
.button2:active {
  background-color: var(--darkGrey) ;
  color: var(--white) ;
  outline: none ;
  box-shadow: none ;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  /* transform: translateY(1px);  */
}

.button3 {
  background-color: var(--white) ;
  font-size: "14px" ;
  font-family: "'Roboto', sans-serif" ;
  outline: none ;
  border-color: var(--bg);
  border-radius:15px ;
  border: 2px solid var(--black) ;
  box-shadow: none ;
  width: 311px ;
  height: 48px ;
  font-weight: 500 ;
  line-height: 16px ;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  cursor: pointer;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
}
.buttonText2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
   text-align: center;
  font-family: 'Roboto', sans-serif ;
  color: var(--darkGrey) ;
}
.buttonText:hover,
.buttonText:focus,
.buttonText:active {
  text-align: "center";
  font-size: "14px" ;
  font-family: 'Roboto', sans-serif ;
  color: var(--darkGrey) ;
}

.buttonText1 {
  color: var(--white)!important;
}











.buttonfollow {
  width: 80px ;
  height: 24px ;
  border: 2px solid var(--bg);
 box-sizing: border-box;
 border-radius: 15px;
 font-family: 'Roboto', sans-serif;
 font-weight: 300;
 font-size: 14px;
 color: var(--darkGrey);
 background-color: var(--white);
 line-height: 10px;
 align-items: center;
 text-align: center;
 cursor: pointer;
 z-index: 1000;
 
}

.buttonfollow:active {
  background-color: var(--white);
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  cursor: pointer;
}

.buttonunfollow {
  width: 80px ;
  height: 24px ;
  border: 2px solid var(--darkGrey);
 box-sizing: border-box;
 border-radius: 15px;
 font-family: 'Roboto', sans-serif;
 font-weight: 300;
 font-size: 14px;
 color: var(--white);
 background-color:var(--buttongrey);
 line-height: 10px;
 align-items: center;
 text-align: center;
 cursor: pointer;
}



.buttonunfollow:active {
  background-color:var(--buttongrey);
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  cursor: pointer;
}

.button1:disabled {
  background: #7fbaebdc;
}
.appnavbar {
  width: 100%;
}
.navbar .logotext {
  cursor: pointer;
}

.navbar {
  background: #ffff;
  box-shadow: 0px 0px 5px 2px rgba(163, 209, 228, 0.781);
}
.navbar .apptitle {
  color: #429bee;
  margin-bottom: 0px;
}
.navbar .apptitle img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.navbar .searchicon {
  color: rgb(162 159 159 / 50%);
}
.nav-link.active .searchicon {
  color: #429bee;
}

.navbar .nav-item .nav-link .searchicon path {
  stroke-width: 0px;
}
.navbar-light .navbar-nav .nav-link {
  color: white;
}
.navbar .nav-item .nav-link path {
  stroke: #212529;
  stroke-width: 7px;
}
.navbar .dpround2 .profilecircle2 {
  height: 50px;
  width: 50px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.navbar .dropdown .dropdown-toggle {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 20px;
  margin-right: 40px;
}
.navbar .dpround2 .profilecircle2 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.navbar .nav-item .active path {
  stroke: var(--accent);
  stroke-width: 10px;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #429bee;
  border-bottom: 5px solid #429bee;
}
.navbar .nav-item .addpost path {
  stroke: var(--accent);
  stroke-width: 20px;
  fill: #429bee;
}
.navbar .dropdown .dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
}
.navbar .nav-item:hover path {
  color: #429bee;
  stroke: var(--accent);
  stroke-width: 20px;
}
.navbar .nav-item .addpost {
  color: #429bee;
}
.navbar .rounded-circle {
  border: 4px solid #429bee;
  height: 40px;
  width: 40px;
  padding: 3px;
  box-shadow: 0px 4px 4px rgba(88, 183, 238, 0.2);
  margin: 0 auto;
}
.navbar .nav-item .addpost:hover .rounded-circle {
  background: #429bee;
  color: #fff;
}

.navbar .nav-item .active .rounded-circle {
  background: #429bee;
  color: #fff;
}
.navbar .nav-item .addpost.active path {
  stroke: var(--accent);
  stroke-width: 20px;
  fill: #ffff;
}

.navbar .nav-item .addpost:hover path {
  fill: #ffff;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.8em;
  padding-left: 1.8em;
  text-align: center;
}

.navbar-expand-lg .navbar-nav .iconarea {
  width: 40px;
  height: 40px;
}

.navbar .sidebartoogle:hover path {
  color: unset;
  stroke: unset;
  stroke-width: unset;
}

.navbar .sidebartoogle {
  padding: 0.5em;
  padding-left: 1.8em;
  padding-right: 0.1em;
}

.navbar .sidebartoogle:hover {
}
.connect2 {
  border-radius: 20px;
  background-color: #bbaeae;
  color: #0c0c0d;
  border-color: #8c8c8c;
  padding-left: 20px;
  padding-right: 20px;
}

.connect2:hover {
  border-radius: 20px;
  background-color: #bbaeae;
  color: #0c0c0d;
  border-color: #8c8c8c;
  padding-left: 20px;
  padding-right: 20px;
}
.navbar-nav {
  flex-direction: row;
}
@media screen and (max-width: 576px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1em;
    padding-left: 1em;
  }
  .navbar-brand {
    display: none;
  }
  .navbar-nav {
    flex-direction: column;
  }
  .navbar-nav .nav-item {
    margin-bottom: 20px;
  }
  .navbar .dropdown .dropdown-toggle {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-nav .iconarea {
    width: 20px;
    height: 20px;
  }
  .navbar .apptitle {
    font-size: 0.8em;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }

  .navbar .rounded-circle {
    height: 25px;
    width: 25px;
    line-height: 0px;
    border: 3px solid #aab7ff;

    padding: 4px;
  }
  #navibar .navbar .plus {
    width: 10px;
    height: 10px;
    line-height: 10px;
  }
  .navbar .sidebartoogle {
    padding-left: 0.7em;
    color: dimgrey;
  }
  .navbar-light .navbar-nav .nav-link.active {
    border-bottom: 3px solid #aab7ff;
  }
}

@media screen and (max-width: 576px) {
  .navbar .apptitle img {
    width: 40px;
    height: 40px;
  }
  .navbar {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }
}

@media screen and (max-width: 768px) {
}

.connect {
  border-radius: 20px;
  background-color: #fff;
  color: #429bee;
}
.connect:hover {
  background-color: #fff;
  color: #429bee;
}

.form-control:focus {
  border-color: var(--blue-f8) !important;
  box-shadow: none !important;
  margin: none !important;
}

.input2 {
    width: 120%;
    height: 45px;
    /* margin: 8px 0; */
    border: 0.5px #fefefe solid;
    border-radius: 15px;
    padding: 0 20px;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    mix-blend-mode: normal;
    box-shadow: 1px 0px 8px 0px #dee2e663;
    margin-top: 5px;
    margin-bottom: -10px;
    margin-left: 30px;
}

.form-group {
  margin-bottom: 15px !important;
}

.invalid-feedback {
  font-family: "Roboto", sans-serif !important;
  display: none;
  width: 100%;
  font-weight: 300;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--off-white);
}
/* .margin {
    margin-bottom: 15px;
  } */

.passwordIcon {
  position: absolute;
  margin-left: 104px;
  margin-top: -35px;
  width: 20px;
}
 

.dpround .profilecircle {
  height: 130px;
  width: 130px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.dpround .profilecircle2 {
  height: 50px;
  width: 50px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.dpround .profilecircle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.dpround .imageedtiIcon img {
  width: 32px;
  height: 32px;
}
.dpround .imageedtiIcon {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 47%;
  background-color: white;
  -webkit-transform: translateX(48%);
          transform: translateX(48%);
  padding: 8px;
  border-radius: 50%;
}
.dpround .imageedtiIcon:hover {
  cursor: pointer;
}
.dpround {
  position: relative;
}
.dpround .editcamera {
  width: 32px;
  height: 32px;
  position: absolute;
  right: -3px;
  z-index: 0;
  background: #d8d8d8;
  border-radius: 50%;
  bottom: 25px;
  cursor: pointer;
}
.dpround .cameracircle {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dpround .editcamera img {
  margin: 0 auto;
  width: 20px;
  height: auto;
  border-radius: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.dpround .userbio {
  /* display: inline-block;
   margin-left: 10px; */
  margin: 0 auto;
  position: relative;
  width: 180px;
  text-align: center;
}
.dpround .username {
  font-size: 20px;
  font-weight: 400;
  color: #4f4f4f;
}
.dpround .title {
  font-weight: 300;
  font-size: 14px;
  color: #6fcf97;
}
/* .green--Text {
    color: var(--greenText);
    font-family:  'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  } */

.dpround .edit {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  cursor: pointer;
}

/* @media screen and (max-width: 768px) {
  .pen {
    left:410px;
  }
} */

@media screen and (max-width: 2000px) {
  .dpround .editbanner {
    top: 80px;
    position: absolute;
    right: 10px;
    background: #d8d8d8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4f4f4f, 100%;
    padding: 5px;
    cursor: pointer;
  }
  .dpround .editbanner img {
    width: 20px;
    display: inline;
    margin-right: 5px;
    cursor: pointer;
  }
}

@media screen and (max-width: 991px) {
  .dpround .editbanner {
    top: -123px;
    right: 0px;
    padding: 7px;
    border-radius: 50%;
    padding-bottom: 9px;
  }
  .dpround .cameratext {
    display: none;
  }
  .dpround .editbanner img {
    display: inline;
    margin-right: 0px;
    cursor: pointer;
  }
}

/* .dpround .editbanner{
  top:80px;
  position: absolute;
  right: 10px;
  background: #D8D8D8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #4F4F4F, 100%;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .dpround .editbanner{
    top:-280px;
    position: relative;
    background: #D8D8D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4F4F4F, 100%;
    padding: 5px;
    cursor: pointer;
    left: 60%;
    width: 40%;
}
}

.dpround .editbanner img{
  width: 20px;
  display: inline;
  margin-right: 5px;
  cursor: pointer;
} */

.dpround .profilecircle {
  margin: 0 auto;
}

.dproundcreator .profilecirclecreator {
  height: 45px;
  width: 45px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.dproundcreator .profilecirclecreator img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.dproundcreator .profilecirclecreator {
  margin: 0 auto;
}

 
:root {
    --greyLight: #f1f1f1;
    --heading1: #4a4a4a;
    --accent: #ffaaac;
    --red:#dc3545;
  }

.signuplabel {
    background-color: var(--white) ;
    font-family: 'Roboto', 'sans-serif' ;
    border-radius: 8px ;
    border: 2px solid rgba(0, 0, 0, 0.09) ;
    width: 311px ;
    height: 30px ;
    font-weight: 500 ;
    cursor: pointer;
  }

.signuplabel2 {
  background-color: #ffaaac;
  background-color: var(--accent) ;
  font-family: 'Roboto', 'sans-serif' ;
    border-radius: 8px ;
    border: 2px solid rgba(0, 0, 0, 0.09) ;
    width: 311px ;
    height: 30px ;
    font-weight: 500 ;
    cursor: pointer;
    color: white;
  /* box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09); */
  /* transform: translateY(1px);  */
}

  .lablealign{
      display: flex;
  }

  .labelmargin1{
      margin-left: 1px;
      padding-top: 4px;
  }
  .labelmargin2{
      margin-right: 1px;
      padding-top: 4px;
  }

  .labelicon{
      margin-right: 5px;
      margin-bottom: 5px;
  }
.profilenavbar .active {
  border-bottom: 3px solid var(--coloractive);

  color: #364eff;
  background: #ffffff63;
  border-radius: 15px;
  box-shadow: 0px 11px 20px 3px #dee2e6c7;
}

.profilenavbar a {
  color: var(--darkGrey);
  font-weight: 400;
  font-size: 14px;
  background: #ffffff63;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 4px #dee2e64a;
  margin-left: 15px;
  margin-bottom: 10px;
  border: 1px solid #f8f9fa;
}
.profilenavbar {
  position: relative;
  margin-bottom: -5px;
}

.explorernavbar .active {
  border-bottom: 3px solid var(--coloractive);
  color: #fbfbfb;
  background: #429bee;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 0px #dee2e6c7;
  border-color: #429bee;
}

.explorernavbar a {
  color: var(--darkGrey);
  font-weight: 400;
  font-size: 14px;
  background: #ffffff63;
  border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  margin-bottom: 10px;
  border: 1px solid #cdd0d2;
}
.explorernavbar {
  position: relative;
  margin-bottom: -5px;
}

.detailsnavbar .active {
  border-bottom: 3px solid var(--coloractive);
  color: #fbfbfb;
  background: #429bee;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 0px #dee2e6c7;
  border-color: #429bee;
}

.detailsnavbar a {
  color: var(--darkGrey);
  font-weight: 400;
  font-size: 14px;
  background: #ffffff63;
  border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  margin-bottom: 10px;
  border: 1px solid #cdd0d2;
}
.detailsnavbar {
  position: relative;
  margin-bottom: -5px;
}
.portfolionavbar {
  margin: 20px;
}
.portfolionavbar .nav-link.active {
  background-color: #f1f1ff;
  border-radius: 12px;
}

#productdetails .content {
  width: 100%;
  margin-top: 100px;
}
#productdetails .plus {
  width: 20px;
  height: 20px;
  line-height: 10px;
  color: #ffaaac;
}

#productdetails .minus {
  width: 23px;
  height: 23px;
  line-height: 10px;
  color: #ffaaac;
}

#productdetails .productquantity input {
  width: 33px;
  height: 20px;
  margin-left: 7px;
  margin-right: 7px;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  outline-color: var(--accent);
}
#productdetails .sidebar {
  height: 100%;
  width: 300px;
  position: absolute;
  right: 0px;
}
#productdetails .leftsidebar {
  border-right: 1px solid #f1f1f1;
}
#productdetails .productimage {
  width: 100%;
  height: 285px;
}
#productdetails .productimage img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

#productdetails .producttitlearea {
  width: 100%;
}

#productdetails .sliderimg2 {
  height: 285px;
  width: 100%;
}

#productdetails .producttitle {
  font-weight: 600;
  font-size: 18px;
  font-family: 'Roboto';
  margin-left: 1000px;
}
#productdetails .productdescriptionarea {
  margin-top: 10px;
  width: 100%;
  height: auto;
  color: #151515;
}
#productdetails .productdescription {
  font-weight: 400;
  font-size: 17px;
  color: #151515;
  text-align: left;
  line-height: 23.15px;
}
#productdetails .discount {
  color: #6a983c;
  font-weight: 600;
  background-color: #f4f8ec;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 8px;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 12px;
}

#productdetails .categorytitle {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
}

#productdetails .categorydescription {
  font-size: 14px;
}

#productdetails .displaycattext {
  display: flex;
  margin-top: 17px;
}
#productdetails .displaytext {
  /* margin-left: -58%; */
  /* display: inline-flex; */
  width: 100px;
}
#productdetails .displaytext2 {
  /* margin-left: -58%; */
  margin-top: 12px;
}

.dolarstriketext {
  font-weight: 600;
  font-size: 12px;
  color: #a9a9a9;
  line-height: 18px;
}

#productdetails body {
  text-align: unset;
}

#productdetails .uploadtext {
  z-index: 2500;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#productdetails .productcostarea {
  padding: 20.5px;
  border: 1px solid rgba(149, 151, 161, 0.2);
  border-radius: 8px;
  width: 100%;
  height: 89px;
  margin-top: 28px;
  position: relative;
}

.productdetails {
  text-align: left;
  /* width: 534px; */
  margin-top: 10px;
}
.textareas {
  display: grid;
  width: 165px;
  bottom: 16px;
  position: absolute;
}

.dolartext {
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

#productdetails .productquantity {
  width: 111px;
  height: 47px;

  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #828282;

  right: 28%;
  bottom: 20.5px;
  position: absolute;
  padding: 8px;
}

#productdetails p {
  margin-bottom: 5px;
}

#productdetails .productcart {
  width: 142px;
  height: 47px;
  background-color: var(--accent);
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid var(--greenText);
  color: white;
  right: 5%;
  bottom: 20.5px;
  position: absolute;
  padding: 5px;
}
.productcart2 {
  color: var(--error);
  right: 5%;
  bottom: 15px;
  position: absolute;
  padding: 5px;
}

#productdetails .carttext {
  font-weight: 700;
  font-size: 15px;
  margin-left: 5%;
}

#productdetails .quantitytext {
  font-weight: 700;
  font-size: 15px;
  margin-left: 5%;
  border-right: 2px solid #f1f1f1;
  padding-right: 20%;
}

#productdetails .cartlogo {
  margin: 7px;
}
#productdetails .pluslogo {
  margin-left: 10%;
}

#nftlogs Table tbody tr:hover {
  /* cursor: pointer; */
}
@media screen and (max-width: 991px) {
  #productdetails .leftsidebar {
    border-right: none;
  }
}

@media screen and (max-width: 768px) {
  #productdetails .content {
    margin-top: 50px;
  }
  #productdetails .productquantity {
    right: 40%;
  }
}

@media screen and (max-width: 415px) {
  #productdetails .productquantity {
    /* width: 80px; */
    right: 25%;
  }
  /* #productdetails .productcart {
    width: 100px;
  } */
  #productdetails .productcart {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
  #productdetails .carttext {
    /* font-weight: 600;
    font-size: 12px; */
    display: none;
  }
  #productdetails .productcostarea {
    margin-top: 40px;
  }
  #productdetails .dolartext {
    font-size: 20px;
  }
}

@media screen and (max-width: 376px) {
  #productdetails .leftsidebar {
    border-right: none;
  }

  #productdetails .productcart {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
  #productdetails .carttext {
    display: none;
  }
  /* #productdetails .cartlogo {
  margin: 13px;
} */
  #productdetails .dolartext {
    font-weight: 600;
    font-size: 14px;
  }
  /* #productdetails .productquantity {
  width: 60px;
} */
  #productdetails .productcostarea {
    margin-top: 40px;
    height: 80px;
  }
}

.nfti {
  width: 100%;
  border-radius: 8px;
  height: 380px;
}

.buybtn {
  width: 120px;
  height: 50px;
}

.title {
  text-align: left;
  font-family: Roboto;
  font-size: 35px;
}

.details {
  color: #429bee;
  text-align: left;
  font-family: Roboto;
  font-size: 20px;
  /* margin-left: 260px; */
}
.txt {
  color: #758ca1;
  /* text-align: right; */
  font-family: Roboto;
  font-size: 15px;
}
.vl {
  border-left: 6px solid green;
  height: 500px;
}

.editpen img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.nfttitle {
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 32px;
  text-align: left;
  /* margin-left: 260px; */
}


  .load {
    z-index: 1000;
    position: fixed;
top:0;
left: 0;
    background-color:#ffffffd1;
    width: 100%;
    height: 100%;

  }
  .loader{
    position: absolute;
    top:50%;
    left: 50%;

    margin-top: -17.2px;
    margin-left: -42px;

  }


/* @media screen and (max-width: 991px) {
  .load .loader {
    z-index: 1000;
    position: fixed;
    background-color: rgba(153, 149, 149, 0.3);
    width: 100%;
    height: 100%;
  }
} */




 .creator .profile {
    height: 130px;
    width:130px;
    /* border: 3px solid #f1f1f1; */
    border-radius: 50%;
    position: relative;
  }
  .creator .profile img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
 

  .creator .editcamera {
    width: 32px;
    height: 32px;
    position: absolute;
   right: -3px;
   z-index: 0;
   background: #D8D8D8;
   border-radius: 50%;
   bottom: 25px;
   cursor: pointer;
  }
  .creator .cameracircle{
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .creator .editcamera img{
    margin: 0 auto;
width: 20px;
height: auto;
border-radius: unset;
position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer;
}
 .creator .userbio{
   /* display: inline-block;
   margin-left: 10px; */
   margin: 0 auto;
   position: relative;
   width: 180px;
   text-align: center;
 }
 .creator .username{
   font-size: 20px;
   font-weight: 400;
   color: #4F4F4F;
 }
.creator .title{
  font-weight: 300;
  font-size: 14px;
  color: #6FCF97;
}
/* .green--Text {
    color: var(--greenText);
    font-family:  'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  } */

  
  .creator .edit {
   width:12px;
   height: 12px;
   margin-left: 4px;
   cursor: pointer;
  }
  
/* @media screen and (max-width: 768px) {
  .pen {
    left:410px;
  }
} */


@media screen and (max-width: 2000px) {
  .creator .editbanner{
    top:80px;
    position: absolute;
    right: 10px;
    background: #D8D8D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4F4F4F, 100%;
    padding: 5px;
    cursor: pointer;
  }
  .creator .editbanner img{
    width: 20px;
    display: inline;
    margin-right: 5px;
    cursor: pointer;
  }

}

@media screen and (max-width: 991px) {
.creator .editbanner{
  top: -123px;
  right: 0px;
  padding: 7px;
  border-radius: 50%;
  padding-bottom: 9px;
}
.creator .cameratext{
display: none;
}
.creator .editbanner img{
  
  display: inline;
  margin-right: 0px;
  cursor: pointer;
}
}

/* .creator .editbanner{
  top:80px;
  position: absolute;
  right: 10px;
  background: #D8D8D8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #4F4F4F, 100%;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .creator .editbanner{
    top:-280px;
    position: relative;
    background: #D8D8D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4F4F4F, 100%;
    padding: 5px;
    cursor: pointer;
    left: 60%;
    width: 40%;
}
}

.creator .editbanner img{
  width: 20px;
  display: inline;
  margin-right: 5px;
  cursor: pointer;
} */

.creator .profile  {
 margin: 0 auto;
}

.description{
 font-size: 15px;
 font-family: Roboto;
}

.destitle{
  color: #aaa5a5;
  font-size: 14px;
  font-family: Roboto;
 }

.pop{
  width:300px;
}
.popup{
    background: #ffffff;
    border-radius: 8px;
    box-shadow:0px 1px 2px rgb(189 189 189 / 50%);
    width:auto;
    height: auto;
    overflow: hidden;
}

.popup .popupcontent{
    margin: 15px;
    position: relative;
    margin-top:40px;
    
      }

      
.post .insidefield{
    position: absolute;
    top:60px;
}
 .popup .closeicon{
   
    position: absolute;
    /* top: 0px;*/
    margin-top:5px;
    right: 23px; 
 }     
.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  text-transform: uppercase;
}
.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  text-transform: capitalize;
}
.discount {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  color: #000000;
}
.amount {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  color: #000000;
}
.productcard .sliderimg {
  height: 180px;
  width: 100%;
}

.dropdownbox {
  height: 20px;
}
.textwraps {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.productcard .threedot {
    background-color: transparent;
    position: absolute;
    top: 10px;
    /* right: 0px; */
    padding: 0px;
    border: none;
    outline: none;
    height: 20px;
}
.productcard .postinfo {
  margin-left: 60px;
  cursor: pointer;
}
.postcard .dpcircle {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}
.postcard .dpcircle img {
  width: 100%;
  height: 100%;
}

.productcard .postdetail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #828282;
  margin-top: 10px;
}

.productcard {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 30px 0px rgb(167 167 167 / 16%);
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.productcard:hover {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 30px 0px rgb(167 167 167 / 50%);
}
.productcard .postcontent {
  padding: 25px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.productcard .datetime,
.postcard .posttitle {
  display: block;
  text-align: left;
  cursor: pointer;
}
.productcard .threedot img {
  width: 100%;
  vertical-align: none;
  height: 100%;
  cursor: pointer;
  outline: none;
}

.productcard .show > .btn-primary.dropdown-toggle {
  color: unset;
  background-color: transparent;
  border: unset;
}
.productcard .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.productcard .dropdown-toggle::after {
  display: none;
}

.productcard .dropdown {
  position: absolute;
  display: inline-block;
  top: 0px;
  right: 0px;
  width: 20px;
}
.productcard .dropdown.show {
  display: inline-block;
}

.productcard .dropdown-item:focus,
.dropdown-item:hover {
  color: #ffff;
  text-decoration: none;
  background-color: #007bff;
  cursor: pointer;
}

.productcard .posttitle {
  font-weight: 500;
  color: #4f4f4f;
  font-size: 17px;
  line-height: 18px;
  cursor: pointer;
}
.productcard .datetime {
  color: #333333;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  margin-top: 5px;
}

.postcard .postimage {
  width: auto;
  height: auto;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
}

.productcard .actioninfo {
  display: block;
}

.postcard.displayimg {
  width: auto;
}

.productcard .producttitle {
  display: flex;
  font-weight: 800;
  font-size: 32px;
  color: #000000;
  text-transform: capitalize;
}
.productcard .productdiscount {
  display: flex;
  font-weight: 800;
  font-size: 28px;
  color: #000000;
}
.productcard .productamount {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  color: #007bff;
}

.productcard .productdescription {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: #888888;
}
.postimage .slider-wrapper {
  height: 275px;
}
.postimage .carousel .slide img {
  width: 100%;
  height: auto;
}

@media (max-width: 1500px) {
  .postimage .slider-wrapper {
    height: 275px;
  }
}
@media (max-width: 370px) {
  .postimage .slider-wrapper {
    height: 150px;
  }
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.shareform .sharetextbox {
  /* border: 1px; */
  resize: none;
}

.productcard .shareform,
.productcard textarea.form-control,
.productcard .form-group {
  height: 95%;
  width: 98%;
}

.productcard .menu {
  border-radius: 8px;
}

.productcard .searchbutton {
  margin-left: 2px;
  color: var(--accent);
  cursor: pointer;
}

.productcard .productdtl {
  display: flex;
}

.productcard .strikamount {
  color: #a9a9a9;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
}

.productcard .productcart {
  width: 40px;
  height: 40px;
  background-color: var(--accent);
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid var(--greenText);
  color: white;
  right: 25px;
  bottom: 25px;
  position: absolute;
}

.productcard .productcart2 {
  color: white;
  right: 25px;
  bottom: 25px;
  position: absolute;
}

.productcard .cartlogo {
  margin: 7px;
}

.productcard .text {
  color: var(--error);
}
.productcard .imagearea {
  width: 100%;
  height: 180px;
}
.productcard .imagearea img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.productcard .discount {
  color: #6a983c;
  font-weight: 600;
  background-color: #f4f8ec;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 8px;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
}

@media screen and (max-width: 3000px) {
  .productcard .producttitle {
    font-size: 15px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .productcard .producttitle {
    font-size: 15px;
  }
  .productcard {
    width: 100%;
    overflow: hidden;
  }
  .productcard .imagearea {
    height: 150px;
  }
}

@media screen and (max-width: 991px) {
  .productcard .producttitle {
    font-size: 14px;
  }
  .productcard .productamount {
    font-size: 15px;
  }
  .productcard {
    width: 100%;
    height: 275px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .productcard .imagearea {
    width: 100%;
    height: 150px;
  }
}

@media screen and (max-width: 330px) {
  .productcard .producttitle {
    font-size: 12px;
  }
  .productcard .productamount {
    font-size: 14px;
  }

  .productcard {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .productcard .imagearea {
    width: 100%;
    height: 145px;
  }
}


#markethome .categoriesheader{
    color: #4F4F4F;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    /*margin-top: 80px;*/
    }
    
    #markethome .categorytype{
      text-align: left;
      font-size: 14px;
    font-weight: 400;
    color:#828282;
    display: grid;
    /* text-decoration: underline; */
    cursor: pointer;
    }
    #markethome .searchbutton {
      text-decoration: none;
    }
    
    #markethome .searchbox{
      margin-left: 30%;
    }
    
    #markethome .labelcolor{
      color: var(--accent);
    }
    
    #markethome .clearbutton{
      width: 100px;
      height: 48px;
      cursor: pointer;
      border-radius: 50%;
      color: white;
      background: var(--accent);
      border: 2px solid var(--greenText);
      box-sizing: border-box;
      border-radius: 12px;
      margin-left: 5%;
      padding-top: 10px;
      font-weight: 700;
    }
    
    #markethome .content{
      width: 100%;
      margin-top: 30px;
    }
    
    #markethome .sidebar{
      height: 100%;
      width: 300px;
      position: absolute;
      right: 0px
    }
    #markethome .leftsidebar
    {
      border-right: 1px solid #f1f1f1;
    }
    
    #markethome .uploadtext{
      z-index: 2500;
      position: fixed;
      bottom: 10px;
      right: 10px;
    }
    
    #markethome .searcharea{
      display: flex;
    
    }
    @media screen and (max-width: 991px) {
    
      #markethome .leftsidebar
    {
      border-right: none;
    }
    
    #markethome .searchbox{
      margin-left: unset;
      margin-top: 15px;
    } 
    
    
    }

    .ecard{
     border-radius: 20px;
     margin: 10px;
     height: 350px;
     width: 260px;
     box-shadow: 0px 5px 30px 0px rgb(167 167 167 / 16%);
     border:1px solid transparent;
    }

    .eimg{
      width: 150px;
      height: 120px;

    }

  #createpost .addpost{
  background-color: #ffff;
  }
  .gallery2 {
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: inline-block;
    padding: 5px;
    cursor: pointer;
    margin-top: 20px;
}
  #createpost .editcamera img{
    margin: 0 auto;
    width: 20px;
    height: auto;
    cursor: pointer;
}

#createpost .sharesomethingcard

{
height: 400px;
}

#createpost .sharesomethingcard .dpcircle {
top:15px;
-webkit-transform: unset;
        transform: unset;
margin-top:unset;


}

#createpost .postform{
    -webkit-transform: unset;
            transform: unset;
    margin-top:unset;
    top:15px; 
 
}
#createpost .sharesomethingcard .privacyselect {
    margin-top: 13px;
}
#createpost .container{
    margin-top: 15px;
}

#createpost .upload{
   box-shadow:  0px 1px 2px #F1F1F1;
   height: auto;
}
#createpost .attachimage img{
    border: 1px solid var(--bg);
    padding: 0px;

}
#createpost .attachimage{
}
#createpost .attach{
    margin-top: 15px;
border-radius: 8px;
box-shadow: 0px 0px 3px 1px #f1f1f1;
position: relative;
height: 70px;}


#createpost .addtopost{
    position: absolute;
    left:15px;
    margin-top:  25px;
    font-family: "Roboto";
    font-weight: 300;
   font-size: 14px;
   line-height: 18px;
   color:var(--darkGrey);
}
#createpost .cameraarea,#createpost .gallery,#createpost .attachment{
width: 30px;
height: 30px;
overflow: hidden;
display: inline-block;
padding: 5px;
cursor: pointer;
}

/* #createpost .createpost{
    margin-top: 15px;
} */
#createpost .cameraarea img, #createpost .gallery img,#createpost .attachment img{
height: 100%;
width: 100%;
}

#createpost .gallery img{
    top:-10px;
}

#createpost .actions{
    position: absolute;
    right:15px;
    top:-5px;
}

#createpost .attachment img{
    border: 2px solid var(--bg);
    margin-top: 5px;
  
}


#createpost .attachment {
    height: 50px;
    width: 50px;
    cursor: pointer;
    margin-top: 10px;
}
#createpost .attachmentimg {
   
}

.cross{
    height:33px;
  }

  #createpost .imageabsolute{   position: absolute;
    margin-left: -12px;
  }
#createpost .attachmentarea div{
display: inline-block;
}
#createpost .buttonmain{
    margin-top:25px;
}

 .sharetextbox{
    border: 0px;
    resize:none;
  }

#createpost .attachmentarea {
    margin-top: -72px;
    width: 300px;
    float: right;
    margin-right: 70px;
}
#createpost .attachimage{
    margin:5px;
}
#createpost .mobilebtn{
    display: none;
}
  @media screen and (max-width: 991px) {
    #createpost .attachmentarea {
    
        float: unset;
        margin: 0 auto;
       
    }
    #createpost .attachment {
       
        cursor: pointer;
        margin-top: 10px;
    }
    #createpost .attachmentarea{
        margin-top: 10px;
    
    }
    #createpost .desktopbtn{
        display: none;
    }
    #createpost .mobilebtn{
        display: inline;
        display: initial;
    }
    .imageabsolute{
        height: 18px;
        width: 18px;
    }
    .gallery2 {
        width: 35px;
        height: 35px;
        overflow: hidden;
        display: inline-block;
        
        cursor: pointer;
        margin-top: 20px;
    }
    }
  
#createpost .addpost {
  background-color: #ffff;
}
.gallery2 {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  margin-top: 20px;
}
#createpost .editcamera img {
  margin: 0 auto;
  width: 20px;
  height: auto;
  cursor: pointer;
}

#createpost .sharesomethingcard {
  height: 400px;
}

#createpost .sharesomethingcard .dpcircle {
  top: 15px;
  -webkit-transform: unset;
          transform: unset;
  margin-top: unset;
}

#createpost .postform {
  -webkit-transform: unset;
          transform: unset;
  margin-top: unset;
  top: 15px;
}
#createpost .sharesomethingcard .privacyselect {
  margin-top: 13px;
}
#createpost .container {
  margin-top: 15px;
}

#createpost .upload {
  box-shadow: 0px 1px 2px #f1f1f1;
  height: auto;
}
#createpost .attachimage img {
  border: 1px solid #aacfff;
  padding: 0px;
}
/* #createpost .attachimage{
  } */
#createpost .attach {
  margin-top: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px #f1f1f1;
  position: relative;
  height: 70px;
}

#createpost .addtopost {
  position: absolute;
  left: 15px;
  margin-top: 25px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: var(--darkGrey);
}
#createpost .cameraarea,
#createpost .gallery,
#createpost .attachment {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
}

/* #createpost .createpost{
      margin-top: 15px;
  } */
#createpost .cameraarea img,
#createpost .gallery img,
#createpost .attachment img {
  height: 100%;
  width: 100%;
}

#createpost .gallery img {
  top: -10px;
}

#createpost .actions {
  position: absolute;
  right: 15px;
  top: -5px;
}

#createpost .attachment img {
  border: 2px solid #aacfff;
  margin-top: 5px;
}

#createpost .attachment {
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin-top: 10px;
}
/* #createpost .attachmentimg {
     
  } */

.cross {
  height: 33px;
}

#createpost .imageabsolute {
  position: absolute;
  margin-left: -12px;
}
#createpost .attachmentarea div {
  display: inline-block;
}
#createpost .buttonmain {
  margin-top: 25px;
}

.sharetextbox {
  border: 0px;
  resize: none;
}
#createpost .nft-img-thumbnail {
  width: 10rem;
  height: 10rem;
  margin: 2rem 0 0 0;
}
#createpost .nft-img-thumbnail img {
  width: 100%;
  height: 100%;
}
#createpost .attachmentarea {
  margin-top: -72px;
  width: 300px;
  float: right;
  margin-right: 70px;
}
#createpost .attachimage {
  margin: 5px;
}
#createpost .mobilebtn {
  display: none;
}
@media screen and (max-width: 991px) {
  #createpost .attachmentarea {
    float: unset;
    margin: 0 auto;
  }
  #createpost .attachment {
    cursor: pointer;
    margin-top: 10px;
  }
  #createpost .attachmentarea {
    margin-top: 10px;
  }
  #createpost .desktopbtn {
    display: none;
  }
  #createpost .mobilebtn {
    display: inline;
    display: initial;
  }
  .imageabsolute {
    height: 18px;
    width: 18px;
  }
  .gallery2 {
    width: 35px;
    height: 35px;
    overflow: hidden;
    display: inline-block;

    cursor: pointer;
    margin-top: 20px;
  }
}

