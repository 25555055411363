.form-control:focus {
  border-color: var(--bg) !important;
  box-shadow: none !important;
  margin: none !important;
}

.input {
  width: 100%;
  height: 49px;
  border: 1px solid var(--greyLight);
  border-width: 0px 0px 2px 0px;

  outline: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.commentinput {
  width: 70%;
  border-radius: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  /* opacity: 0.8; */
  margin-left: 40px;
}

.form-group {
  margin-bottom: 15px !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.invalid-feedback {
  font-family: 'Roboto', sans-serif !important;
  display: none;
  width: 100%;
  font-weight: 300;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--red);
}
/* .margin {
  margin-bottom: 15px;
} */

.passwordIcon {
  position: absolute;
  /* margin-left: 104px; */
  margin-top: -35px;
  width: 20px;
  right: 40px;
}

.searchicon svg {
  position: absolute;
  top: 15px;
  right: 40px;
  cursor: pointer;
}
.form-control {
  border-radius: 0 !important;
}
.amountdollar {
  position: relative;
  z-index: 20;
  margin: 0 !important;
  left: -2px;
  bottom: -36px;
  font-size: 15px;
}
@media screen and (max-width: 991px) {
  .searchicon svg {
    position: absolute;
    top: 30px;
    /* margin-left: 39%; */
  }
}

@media screen and (max-width: 350px) {
  .commentinput {
    width: 60%;
    padding: 0 10px;
  }
}
