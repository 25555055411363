 
:root {
    --greyLight: #f1f1f1;
    --heading1: #4a4a4a;
    --accent: #ffaaac;
    --red:#dc3545;
  }

.signuplabel {
    background-color: var(--white) ;
    font-family: 'Roboto', 'sans-serif' ;
    border-radius: 8px ;
    border: 2px solid rgba(0, 0, 0, 0.09) ;
    width: 311px ;
    height: 30px ;
    font-weight: 500 ;
    cursor: pointer;
  }

.signuplabel2 {
  background-color: var(--accent) ;
  font-family: 'Roboto', 'sans-serif' ;
    border-radius: 8px ;
    border: 2px solid rgba(0, 0, 0, 0.09) ;
    width: 311px ;
    height: 30px ;
    font-weight: 500 ;
    cursor: pointer;
    color: white;
  /* box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09); */
  /* transform: translateY(1px);  */
}

  .lablealign{
      display: flex;
  }

  .labelmargin1{
      margin-left: 1px;
      padding-top: 4px;
  }
  .labelmargin2{
      margin-right: 1px;
      padding-top: 4px;
  }

  .labelicon{
      margin-right: 5px;
      margin-bottom: 5px;
  }