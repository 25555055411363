@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

:root {
  --black: #000000;
  --white: #ffff;
  /* // googlePlus: '#DD4B39'
    // facebook: '#3b5998',
    // twitter: '#1DA1F2',*/
  --error: rgba(200, 0, 0, 0.8);
  --greybg: #f2f2f2;
  --accent: #aacfff;
  --bg: #429bee;
  --heading1: #4a4a4a;
  --greyLight: #f1f1f1;
  --greenText: #6fcf97;
  --darkGrey: #828282;
  --buttongrey: #979797;
  --accentfaint: #5594e0;
  /* Main Colors */
}

.horizontal {
  padding: 15px;
  width: 315px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.horizontalCenter {
  align-items: 'center';
  width: '100%';
}

.tabcontainer {
  margin-bottom: 15px;
}

.phoneinput {
  /* align-items: 'left';
  width: 19%;
  display: inline-block;
  float:left; */
}

.phoneinput .form-control {
  height: 45px;
  margin-top: 1px;
  border: 1px solid var(--greyLight);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(73, 80, 87);
  mix-blend-mode: normal;
  opacity: 0.8;
}

.textinputcontainer {
  margin-left: 2px;

  width: 80%;
  display: inline-block;
}
.textinputcontainer .form-control,
.textinputcontainer .form-group {
  display: inline-block;
  width: 100%;
}
.react-tel-input .boxcon {
  height: 48px;
}

/* .img-m {
  margin-top: 56px;
} */
.pointer {
  cursor: pointer;
}

.signuptip {
  text-align: center;
  margin-top: 2px;
}

body {
  font-family: 'Roboto';
}

.signin {
  width: 60px;
  height: 27px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: var(--greenText);
}

.accent {
  color: var(--accent);
}

.whiteBG {
  background-color: var(--white);
}

.greyBG {
  background-color: var(--greybg);
}

.positionCenter {
  position: absolute;
  align-self: center;
}

.pageTitle {
  color: var(--heading1);
  font-size: 16;
  font-family: 'Roboto', sans-serif;
}

.greenText {
  color: var(--greenText);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.greyText {
  color: var(--darkGrey);
  font-size: 16;
  font-family: 'Roboto', sans-serif;
}

.buttonContainer {
  width: '80%';
}

.rowSpaceBetween {
  flex-direction: row;
  justify-content: space-between;
}

.TextalignCenter {
  text-align: center;
}

.appName {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.innerContainer {
  width: '75%';
}

.smallContainer {
  /* marginVertical: moderateScale(3); */
  border-radius: 8;
  width: width-8;
  align-self: 'center';
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: initial;
}

.btn-primary:hover {
  color: #fff;
  background-color: unset;
  border-color: initial;
}
.btn:focus {
  outline: 0;
  box-shadow: #fff !important;
}

.btn-primary:focus {
  color: #fff;
  background-color: initial;
  box-shadow: initial;
}

/* .customShadow {
  elevation:8
} */

/* .light14 {
  font-size:Fonts.size.h2;
  color: Colors.darkGrey;
  /* letterSpacing: 0.1;
  fontFamily:'Roboto-Light', 
} */

/* .smallContainerPadding{
  paddingVertical:moderateScale(20);
  paddingHorizontal: moderateScale(14);
}, */

/* mh5: {marginLeft: Metrics.horizontal.scale5},
  mv5: {marginVertical: Metrics.vertical.scale5},
  mv12: {marginVertical: Metrics.vertical.scale12},
  mv10: {marginVertical: Metrics.vertical.scale10},
  mv40: {marginVertical: Metrics.vertical.scale40},
  mt30: {marginTop: Metrics.vertical.scale30}, 
  mt60: {marginTop: Metrics.vertical.scale60},  */
/* .btn-primary.focus, .btn-primary:focus,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled):active:focus{
    outline: none;
    box-shadow: unset;
    background-color: initial;
  } */
