
.pop{
  width:300px;
}
.popup{
    background: #ffffff;
    border-radius: 8px;
    box-shadow:0px 1px 2px rgb(189 189 189 / 50%);
    width:auto;
    height: auto;
    overflow: hidden;
}

.popup .popupcontent{
    margin: 15px;
    position: relative;
    margin-top:40px;
    
      }

      
.post .insidefield{
    position: absolute;
    top:60px;
}
 .popup .closeicon{
   
    position: absolute;
    /* top: 0px;*/
    margin-top:5px;
    right: 23px; 
 }     