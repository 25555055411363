body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 12px !important;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  height: 45px !important;
  margin-left: 0;
  background: #ffffff;
  border: none !important;
  border-bottom: 2px solid var(--greyLight) !important;
  border-radius: 0px !important;
  line-height: 25px;
  height: 35px;
  width: 285px !important;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.linkhover:hover {
  cursor: pointer;
  color: blue;
}
.onboarding-box {
  padding: 50px;
  box-shadow: 0px 2px 28px 4px #e2e2e2ab;
  border-radius: 15px;
}
.onboarding-container {
  /* background-color: red; */
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: white !important;
  margin: 3px !important;
  border: 2px solid white !important;
  border-right: 0px white !important;
  border-radius: 3px 0 0 3px;
}
