
#markethome .categoriesheader{
    color: #4F4F4F;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    /*margin-top: 80px;*/
    }
    
    #markethome .categorytype{
      text-align: left;
      font-size: 14px;
    font-weight: 400;
    color:#828282;
    display: grid;
    /* text-decoration: underline; */
    cursor: pointer;
    }
    #markethome .searchbutton {
      text-decoration: none;
    }
    
    #markethome .searchbox{
      margin-left: 30%;
    }
    
    #markethome .labelcolor{
      color: var(--accent);
    }
    
    #markethome .clearbutton{
      width: 100px;
      height: 48px;
      cursor: pointer;
      border-radius: 50%;
      color: white;
      background: var(--accent);
      border: 2px solid var(--greenText);
      box-sizing: border-box;
      border-radius: 12px;
      margin-left: 5%;
      padding-top: 10px;
      font-weight: 700;
    }
    
    #markethome .content{
      width: 100%;
      margin-top: 30px;
    }
    
    #markethome .sidebar{
      height: 100%;
      width: 300px;
      position: absolute;
      right: 0px
    }
    #markethome .leftsidebar
    {
      border-right: 1px solid #f1f1f1;
    }
    
    #markethome .uploadtext{
      z-index: 2500;
      position: fixed;
      bottom: 10px;
      right: 10px;
    }
    
    #markethome .searcharea{
      display: flex;
    
    }
    @media screen and (max-width: 991px) {
    
      #markethome .leftsidebar
    {
      border-right: none;
    }
    
    #markethome .searchbox{
      margin-left: unset;
      margin-top: 15px;
    } 
    
    
    }

    .ecard{
     border-radius: 20px;
     margin: 10px;
     height: 350px;
     width: 260px;
     box-shadow: 0px 5px 30px 0px rgb(167 167 167 / 16%);
     border:1px solid transparent;
    }

    .eimg{
      width: 150px;
      height: 120px;

    }