.dpround .profilecircle {
  height: 130px;
  width: 130px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.dpround .profilecircle2 {
  height: 50px;
  width: 50px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.dpround .profilecircle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.dpround .imageedtiIcon img {
  width: 32px;
  height: 32px;
}
.dpround .imageedtiIcon {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 47%;
  background-color: white;
  transform: translateX(48%);
  padding: 8px;
  border-radius: 50%;
}
.dpround .imageedtiIcon:hover {
  cursor: pointer;
}
.dpround {
  position: relative;
}
.dpround .editcamera {
  width: 32px;
  height: 32px;
  position: absolute;
  right: -3px;
  z-index: 0;
  background: #d8d8d8;
  border-radius: 50%;
  bottom: 25px;
  cursor: pointer;
}
.dpround .cameracircle {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dpround .editcamera img {
  margin: 0 auto;
  width: 20px;
  height: auto;
  border-radius: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.dpround .userbio {
  /* display: inline-block;
   margin-left: 10px; */
  margin: 0 auto;
  position: relative;
  width: 180px;
  text-align: center;
}
.dpround .username {
  font-size: 20px;
  font-weight: 400;
  color: #4f4f4f;
}
.dpround .title {
  font-weight: 300;
  font-size: 14px;
  color: #6fcf97;
}
/* .green--Text {
    color: var(--greenText);
    font-family:  'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  } */

.dpround .edit {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  cursor: pointer;
}

/* @media screen and (max-width: 768px) {
  .pen {
    left:410px;
  }
} */

@media screen and (max-width: 2000px) {
  .dpround .editbanner {
    top: 80px;
    position: absolute;
    right: 10px;
    background: #d8d8d8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4f4f4f, 100%;
    padding: 5px;
    cursor: pointer;
  }
  .dpround .editbanner img {
    width: 20px;
    display: inline;
    margin-right: 5px;
    cursor: pointer;
  }
}

@media screen and (max-width: 991px) {
  .dpround .editbanner {
    top: -123px;
    right: 0px;
    padding: 7px;
    border-radius: 50%;
    padding-bottom: 9px;
  }
  .dpround .cameratext {
    display: none;
  }
  .dpround .editbanner img {
    display: inline;
    margin-right: 0px;
    cursor: pointer;
  }
}

/* .dpround .editbanner{
  top:80px;
  position: absolute;
  right: 10px;
  background: #D8D8D8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #4F4F4F, 100%;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .dpround .editbanner{
    top:-280px;
    position: relative;
    background: #D8D8D8;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #4F4F4F, 100%;
    padding: 5px;
    cursor: pointer;
    left: 60%;
    width: 40%;
}
}

.dpround .editbanner img{
  width: 20px;
  display: inline;
  margin-right: 5px;
  cursor: pointer;
} */

.dpround .profilecircle {
  margin: 0 auto;
}

.dproundcreator .profilecirclecreator {
  height: 45px;
  width: 45px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.dproundcreator .profilecirclecreator img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.dproundcreator .profilecirclecreator {
  margin: 0 auto;
}
