@charset "UTF-8";
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap'); */

/*!
 =========================================================
 * ArchitectUI ReactJS Dashboard Theme - v1.0.0
 =========================================================
 * Product Page: https://dashboardpack.com
 * Copyright 2019 DashboardPack (https://dashboardpack.com)
 * Licensed under MIT (https://github.com/DashboardPack/architectui-react-theme-free/blob/master/LICENSE)
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */


/* .buttonSmall {
    height:moderateScale(30),
    width:moderateScale(80)
} */







.buttonmain {
  /* height:moderateScale(50),*/

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  border-radius: 15px ;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 48px;
  margin: 20px 0;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  
}
.button1 {
  background-color: var(--bg) ;
  color: var(--white);
  box-shadow: 2;
}
.button1:hover,
.button1:focus,
.button1:active {
  background-color: var(--bg) ;
  outline: none ;
  box-shadow: none ;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  /* transform: translateY(1px);  */
}

.button2 {
  background-color: var(--darkGrey);
  color: var(--white);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
}
.button2:hover,
.button2:focus,
.button2:active {
  background-color: var(--darkGrey) ;
  color: var(--white) ;
  outline: none ;
  box-shadow: none ;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  /* transform: translateY(1px);  */
}

.button3 {
  background-color: var(--white) ;
  font-size: "14px" ;
  font-family: "'Roboto', sans-serif" ;
  outline: none ;
  border-color: var(--bg);
  border-radius:15px ;
  border: 2px solid var(--black) ;
  box-shadow: none ;
  width: 311px ;
  height: 48px ;
  font-weight: 500 ;
  line-height: 16px ;
  transform: translateY(1px);
  cursor: pointer;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
}
.buttonText2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
   text-align: center;
  font-family: 'Roboto', sans-serif ;
  color: var(--darkGrey) ;
}
.buttonText:hover,
.buttonText:focus,
.buttonText:active {
  text-align: "center";
  font-size: "14px" ;
  font-family: 'Roboto', sans-serif ;
  color: var(--darkGrey) ;
}

.buttonText1 {
  color: var(--white)!important;
}











.buttonfollow {
  width: 80px ;
  height: 24px ;
  border: 2px solid var(--bg);
 box-sizing: border-box;
 border-radius: 15px;
 font-family: 'Roboto', sans-serif;
 font-weight: 300;
 font-size: 14px;
 color: var(--darkGrey);
 background-color: var(--white);
 line-height: 10px;
 align-items: center;
 text-align: center;
 cursor: pointer;
 z-index: 1000;
 
}

.buttonfollow:active {
  background-color: var(--white);
  transform: translateY(2px);
  cursor: pointer;
}

.buttonunfollow {
  width: 80px ;
  height: 24px ;
  border: 2px solid var(--darkGrey);
 box-sizing: border-box;
 border-radius: 15px;
 font-family: 'Roboto', sans-serif;
 font-weight: 300;
 font-size: 14px;
 color: var(--white);
 background-color:var(--buttongrey);
 line-height: 10px;
 align-items: center;
 text-align: center;
 cursor: pointer;
}



.buttonunfollow:active {
  background-color:var(--buttongrey);
  transform: translateY(2px);
  cursor: pointer;
}

.button1:disabled {
  background: #7fbaebdc;
}