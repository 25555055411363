.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  text-transform: uppercase;
}
.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  text-transform: capitalize;
}
.discount {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  color: #000000;
}
.amount {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  color: #000000;
}
.productcard .sliderimg {
  height: 180px;
  width: 100%;
}

.dropdownbox {
  height: 20px;
}
.textwraps {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.productcard .threedot {
    background-color: transparent;
    position: absolute;
    top: 10px;
    /* right: 0px; */
    padding: 0px;
    border: none;
    outline: none;
    height: 20px;
}
.productcard .postinfo {
  margin-left: 60px;
  cursor: pointer;
}
.postcard .dpcircle {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50%;
  cursor: pointer;
  transform: translate(0%, -50%);
}
.postcard .dpcircle img {
  width: 100%;
  height: 100%;
}

.productcard .postdetail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #828282;
  margin-top: 10px;
}

.productcard {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 30px 0px rgb(167 167 167 / 16%);
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.productcard:hover {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 30px 0px rgb(167 167 167 / 50%);
}
.productcard .postcontent {
  padding: 25px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.productcard .datetime,
.postcard .posttitle {
  display: block;
  text-align: left;
  cursor: pointer;
}
.productcard .threedot img {
  width: 100%;
  vertical-align: none;
  height: 100%;
  cursor: pointer;
  outline: none;
}

.productcard .show > .btn-primary.dropdown-toggle {
  color: unset;
  background-color: transparent;
  border: unset;
}
.productcard .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.productcard .dropdown-toggle::after {
  display: none;
}

.productcard .dropdown {
  position: absolute;
  display: inline-block;
  top: 0px;
  right: 0px;
  width: 20px;
}
.productcard .dropdown.show {
  display: inline-block;
}

.productcard .dropdown-item:focus,
.dropdown-item:hover {
  color: #ffff;
  text-decoration: none;
  background-color: #007bff;
  cursor: pointer;
}

.productcard .posttitle {
  font-weight: 500;
  color: #4f4f4f;
  font-size: 17px;
  line-height: 18px;
  cursor: pointer;
}
.productcard .datetime {
  color: #333333;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  margin-top: 5px;
}

.postcard .postimage {
  width: auto;
  height: auto;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
}

.productcard .actioninfo {
  display: block;
}

.postcard.displayimg {
  width: auto;
}

.productcard .producttitle {
  display: flex;
  font-weight: 800;
  font-size: 32px;
  color: #000000;
  text-transform: capitalize;
}
.productcard .productdiscount {
  display: flex;
  font-weight: 800;
  font-size: 28px;
  color: #000000;
}
.productcard .productamount {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  color: #007bff;
}

.productcard .productdescription {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: #888888;
}
.postimage .slider-wrapper {
  height: 275px;
}
.postimage .carousel .slide img {
  width: 100%;
  height: auto;
}

@media (max-width: 1500px) {
  .postimage .slider-wrapper {
    height: 275px;
  }
}
@media (max-width: 370px) {
  .postimage .slider-wrapper {
    height: 150px;
  }
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.shareform .sharetextbox {
  /* border: 1px; */
  resize: none;
}

.productcard .shareform,
.productcard textarea.form-control,
.productcard .form-group {
  height: 95%;
  width: 98%;
}

.productcard .menu {
  border-radius: 8px;
}

.productcard .searchbutton {
  margin-left: 2px;
  color: var(--accent);
  cursor: pointer;
}

.productcard .productdtl {
  display: flex;
}

.productcard .strikamount {
  color: #a9a9a9;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
}

.productcard .productcart {
  width: 40px;
  height: 40px;
  background-color: var(--accent);
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid var(--greenText);
  color: white;
  right: 25px;
  bottom: 25px;
  position: absolute;
}

.productcard .productcart2 {
  color: white;
  right: 25px;
  bottom: 25px;
  position: absolute;
}

.productcard .cartlogo {
  margin: 7px;
}

.productcard .text {
  color: var(--error);
}
.productcard .imagearea {
  width: 100%;
  height: 180px;
}
.productcard .imagearea img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.productcard .discount {
  color: #6a983c;
  font-weight: 600;
  background-color: #f4f8ec;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 8px;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
}

@media screen and (max-width: 3000px) {
  .productcard .producttitle {
    font-size: 15px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .productcard .producttitle {
    font-size: 15px;
  }
  .productcard {
    width: 100%;
    overflow: hidden;
  }
  .productcard .imagearea {
    height: 150px;
  }
}

@media screen and (max-width: 991px) {
  .productcard .producttitle {
    font-size: 14px;
  }
  .productcard .productamount {
    font-size: 15px;
  }
  .productcard {
    width: 100%;
    height: 275px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .productcard .imagearea {
    width: 100%;
    height: 150px;
  }
}

@media screen and (max-width: 330px) {
  .productcard .producttitle {
    font-size: 12px;
  }
  .productcard .productamount {
    font-size: 14px;
  }

  .productcard {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .productcard .imagearea {
    width: 100%;
    height: 145px;
  }
}
