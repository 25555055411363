.profilenavbar .active {
  border-bottom: 3px solid var(--coloractive);

  color: #364eff;
  background: #ffffff63;
  border-radius: 15px;
  box-shadow: 0px 11px 20px 3px #dee2e6c7;
}

.profilenavbar a {
  color: var(--darkGrey);
  font-weight: 400;
  font-size: 14px;
  background: #ffffff63;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 4px #dee2e64a;
  margin-left: 15px;
  margin-bottom: 10px;
  border: 1px solid #f8f9fa;
}
.profilenavbar {
  position: relative;
  margin-bottom: -5px;
}

.explorernavbar .active {
  border-bottom: 3px solid var(--coloractive);
  color: #fbfbfb;
  background: #429bee;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 0px #dee2e6c7;
  border-color: #429bee;
}

.explorernavbar a {
  color: var(--darkGrey);
  font-weight: 400;
  font-size: 14px;
  background: #ffffff63;
  border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  margin-bottom: 10px;
  border: 1px solid #cdd0d2;
}
.explorernavbar {
  position: relative;
  margin-bottom: -5px;
}

.detailsnavbar .active {
  border-bottom: 3px solid var(--coloractive);
  color: #fbfbfb;
  background: #429bee;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 0px #dee2e6c7;
  border-color: #429bee;
}

.detailsnavbar a {
  color: var(--darkGrey);
  font-weight: 400;
  font-size: 14px;
  background: #ffffff63;
  border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  margin-bottom: 10px;
  border: 1px solid #cdd0d2;
}
.detailsnavbar {
  position: relative;
  margin-bottom: -5px;
}
.portfolionavbar {
  margin: 20px;
}
.portfolionavbar .nav-link.active {
  background-color: #f1f1ff;
  border-radius: 12px;
}
