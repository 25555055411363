#productdetails .content {
  width: 100%;
  margin-top: 100px;
}
#productdetails .plus {
  width: 20px;
  height: 20px;
  line-height: 10px;
  color: #ffaaac;
}

#productdetails .minus {
  width: 23px;
  height: 23px;
  line-height: 10px;
  color: #ffaaac;
}

#productdetails .productquantity input {
  width: 33px;
  height: 20px;
  margin-left: 7px;
  margin-right: 7px;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  outline-color: var(--accent);
}
#productdetails .sidebar {
  height: 100%;
  width: 300px;
  position: absolute;
  right: 0px;
}
#productdetails .leftsidebar {
  border-right: 1px solid #f1f1f1;
}
#productdetails .productimage {
  width: 100%;
  height: 285px;
}
#productdetails .productimage img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

#productdetails .producttitlearea {
  width: 100%;
}

#productdetails .sliderimg2 {
  height: 285px;
  width: 100%;
}

#productdetails .producttitle {
  font-weight: 600;
  font-size: 18px;
  font-family: 'Roboto';
  margin-left: 1000px;
}
#productdetails .productdescriptionarea {
  margin-top: 10px;
  width: 100%;
  height: auto;
  color: #151515;
}
#productdetails .productdescription {
  font-weight: 400;
  font-size: 17px;
  color: #151515;
  text-align: left;
  line-height: 23.15px;
}
#productdetails .discount {
  color: #6a983c;
  font-weight: 600;
  background-color: #f4f8ec;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 8px;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 12px;
}

#productdetails .categorytitle {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
}

#productdetails .categorydescription {
  font-size: 14px;
}

#productdetails .displaycattext {
  display: flex;
  margin-top: 17px;
}
#productdetails .displaytext {
  /* margin-left: -58%; */
  /* display: inline-flex; */
  width: 100px;
}
#productdetails .displaytext2 {
  /* margin-left: -58%; */
  margin-top: 12px;
}

.dolarstriketext {
  font-weight: 600;
  font-size: 12px;
  color: #a9a9a9;
  line-height: 18px;
}

#productdetails body {
  text-align: unset;
}

#productdetails .uploadtext {
  z-index: 2500;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#productdetails .productcostarea {
  padding: 20.5px;
  border: 1px solid rgba(149, 151, 161, 0.2);
  border-radius: 8px;
  width: 100%;
  height: 89px;
  margin-top: 28px;
  position: relative;
}

.productdetails {
  text-align: left;
  /* width: 534px; */
  margin-top: 10px;
}
.textareas {
  display: grid;
  width: 165px;
  bottom: 16px;
  position: absolute;
}

.dolartext {
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

#productdetails .productquantity {
  width: 111px;
  height: 47px;

  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #828282;

  right: 28%;
  bottom: 20.5px;
  position: absolute;
  padding: 8px;
}

#productdetails p {
  margin-bottom: 5px;
}

#productdetails .productcart {
  width: 142px;
  height: 47px;
  background-color: var(--accent);
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid var(--greenText);
  color: white;
  right: 5%;
  bottom: 20.5px;
  position: absolute;
  padding: 5px;
}
.productcart2 {
  color: var(--error);
  right: 5%;
  bottom: 15px;
  position: absolute;
  padding: 5px;
}

#productdetails .carttext {
  font-weight: 700;
  font-size: 15px;
  margin-left: 5%;
}

#productdetails .quantitytext {
  font-weight: 700;
  font-size: 15px;
  margin-left: 5%;
  border-right: 2px solid #f1f1f1;
  padding-right: 20%;
}

#productdetails .cartlogo {
  margin: 7px;
}
#productdetails .pluslogo {
  margin-left: 10%;
}

#nftlogs Table tbody tr:hover {
  /* cursor: pointer; */
}
@media screen and (max-width: 991px) {
  #productdetails .leftsidebar {
    border-right: none;
  }
}

@media screen and (max-width: 768px) {
  #productdetails .content {
    margin-top: 50px;
  }
  #productdetails .productquantity {
    right: 40%;
  }
}

@media screen and (max-width: 415px) {
  #productdetails .productquantity {
    /* width: 80px; */
    right: 25%;
  }
  /* #productdetails .productcart {
    width: 100px;
  } */
  #productdetails .productcart {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
  #productdetails .carttext {
    /* font-weight: 600;
    font-size: 12px; */
    display: none;
  }
  #productdetails .productcostarea {
    margin-top: 40px;
  }
  #productdetails .dolartext {
    font-size: 20px;
  }
}

@media screen and (max-width: 376px) {
  #productdetails .leftsidebar {
    border-right: none;
  }

  #productdetails .productcart {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
  #productdetails .carttext {
    display: none;
  }
  /* #productdetails .cartlogo {
  margin: 13px;
} */
  #productdetails .dolartext {
    font-weight: 600;
    font-size: 14px;
  }
  /* #productdetails .productquantity {
  width: 60px;
} */
  #productdetails .productcostarea {
    margin-top: 40px;
    height: 80px;
  }
}

.nfti {
  width: 100%;
  border-radius: 8px;
  height: 380px;
}

.buybtn {
  width: 120px;
  height: 50px;
}

.title {
  text-align: left;
  font-family: Roboto;
  font-size: 35px;
}

.details {
  color: #429bee;
  text-align: left;
  font-family: Roboto;
  font-size: 20px;
  /* margin-left: 260px; */
}
.txt {
  color: #758ca1;
  /* text-align: right; */
  font-family: Roboto;
  font-size: 15px;
}
.vl {
  border-left: 6px solid green;
  height: 500px;
}

.editpen img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.nfttitle {
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 32px;
  text-align: left;
  /* margin-left: 260px; */
}
