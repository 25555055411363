
  .load {
    z-index: 1000;
    position: fixed;
top:0;
left: 0;
    background-color:#ffffffd1;
    width: 100%;
    height: 100%;

  }
  .loader{
    position: absolute;
    top:50%;
    left: 50%;

    margin-top: -17.2px;
    margin-left: -42px;

  }


/* @media screen and (max-width: 991px) {
  .load .loader {
    z-index: 1000;
    position: fixed;
    background-color: rgba(153, 149, 149, 0.3);
    width: 100%;
    height: 100%;
  }
} */
