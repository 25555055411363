.form-control:focus {
  border-color: var(--blue-f8) !important;
  box-shadow: none !important;
  margin: none !important;
}

.input2 {
    width: 120%;
    height: 45px;
    /* margin: 8px 0; */
    border: 0.5px #fefefe solid;
    border-radius: 15px;
    padding: 0 20px;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    mix-blend-mode: normal;
    box-shadow: 1px 0px 8px 0px #dee2e663;
    margin-top: 5px;
    margin-bottom: -10px;
    margin-left: 30px;
}

.form-group {
  margin-bottom: 15px !important;
}

.invalid-feedback {
  font-family: "Roboto", sans-serif !important;
  display: none;
  width: 100%;
  font-weight: 300;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--off-white);
}
/* .margin {
    margin-bottom: 15px;
  } */

.passwordIcon {
  position: absolute;
  margin-left: 104px;
  margin-top: -35px;
  width: 20px;
}
 
