#createpost .addpost {
  background-color: #ffff;
}
.gallery2 {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  margin-top: 20px;
}
#createpost .editcamera img {
  margin: 0 auto;
  width: 20px;
  height: auto;
  cursor: pointer;
}

#createpost .sharesomethingcard {
  height: 400px;
}

#createpost .sharesomethingcard .dpcircle {
  top: 15px;
  transform: unset;
  margin-top: unset;
}

#createpost .postform {
  transform: unset;
  margin-top: unset;
  top: 15px;
}
#createpost .sharesomethingcard .privacyselect {
  margin-top: 13px;
}
#createpost .container {
  margin-top: 15px;
}

#createpost .upload {
  box-shadow: 0px 1px 2px #f1f1f1;
  height: auto;
}
#createpost .attachimage img {
  border: 1px solid #aacfff;
  padding: 0px;
}
/* #createpost .attachimage{
  } */
#createpost .attach {
  margin-top: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px #f1f1f1;
  position: relative;
  height: 70px;
}

#createpost .addtopost {
  position: absolute;
  left: 15px;
  margin-top: 25px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: var(--darkGrey);
}
#createpost .cameraarea,
#createpost .gallery,
#createpost .attachment {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
}

/* #createpost .createpost{
      margin-top: 15px;
  } */
#createpost .cameraarea img,
#createpost .gallery img,
#createpost .attachment img {
  height: 100%;
  width: 100%;
}

#createpost .gallery img {
  top: -10px;
}

#createpost .actions {
  position: absolute;
  right: 15px;
  top: -5px;
}

#createpost .attachment img {
  border: 2px solid #aacfff;
  margin-top: 5px;
}

#createpost .attachment {
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin-top: 10px;
}
/* #createpost .attachmentimg {
     
  } */

.cross {
  height: 33px;
}

#createpost .imageabsolute {
  position: absolute;
  margin-left: -12px;
}
#createpost .attachmentarea div {
  display: inline-block;
}
#createpost .buttonmain {
  margin-top: 25px;
}

.sharetextbox {
  border: 0px;
  resize: none;
}
#createpost .nft-img-thumbnail {
  width: 10rem;
  height: 10rem;
  margin: 2rem 0 0 0;
}
#createpost .nft-img-thumbnail img {
  width: 100%;
  height: 100%;
}
#createpost .attachmentarea {
  margin-top: -72px;
  width: 300px;
  float: right;
  margin-right: 70px;
}
#createpost .attachimage {
  margin: 5px;
}
#createpost .mobilebtn {
  display: none;
}
@media screen and (max-width: 991px) {
  #createpost .attachmentarea {
    float: unset;
    margin: 0 auto;
  }
  #createpost .attachment {
    cursor: pointer;
    margin-top: 10px;
  }
  #createpost .attachmentarea {
    margin-top: 10px;
  }
  #createpost .desktopbtn {
    display: none;
  }
  #createpost .mobilebtn {
    display: initial;
  }
  .imageabsolute {
    height: 18px;
    width: 18px;
  }
  .gallery2 {
    width: 35px;
    height: 35px;
    overflow: hidden;
    display: inline-block;

    cursor: pointer;
    margin-top: 20px;
  }
}
