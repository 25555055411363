.appnavbar {
  width: 100%;
}
.navbar .logotext {
  cursor: pointer;
}

.navbar {
  background: #ffff;
  box-shadow: 0px 0px 5px 2px rgba(163, 209, 228, 0.781);
}
.navbar .apptitle {
  color: #429bee;
  margin-bottom: 0px;
}
.navbar .apptitle img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.navbar .searchicon {
  color: rgb(162 159 159 / 50%);
}
.nav-link.active .searchicon {
  color: #429bee;
}

.navbar .nav-item .nav-link .searchicon path {
  stroke-width: 0px;
}
.navbar-light .navbar-nav .nav-link {
  color: white;
}
.navbar .nav-item .nav-link path {
  stroke: #212529;
  stroke-width: 7px;
}
.navbar .dpround2 .profilecircle2 {
  height: 50px;
  width: 50px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 50%;
  position: relative;
}
.navbar .dropdown .dropdown-toggle {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 20px;
  margin-right: 40px;
}
.navbar .dpround2 .profilecircle2 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.navbar .nav-item .active path {
  stroke: var(--accent);
  stroke-width: 10px;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #429bee;
  border-bottom: 5px solid #429bee;
}
.navbar .nav-item .addpost path {
  stroke: var(--accent);
  stroke-width: 20px;
  fill: #429bee;
}
.navbar .dropdown .dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
}
.navbar .nav-item:hover path {
  color: #429bee;
  stroke: var(--accent);
  stroke-width: 20px;
}
.navbar .nav-item .addpost {
  color: #429bee;
}
.navbar .rounded-circle {
  border: 4px solid #429bee;
  height: 40px;
  width: 40px;
  padding: 3px;
  box-shadow: 0px 4px 4px rgba(88, 183, 238, 0.2);
  margin: 0 auto;
}
.navbar .nav-item .addpost:hover .rounded-circle {
  background: #429bee;
  color: #fff;
}

.navbar .nav-item .active .rounded-circle {
  background: #429bee;
  color: #fff;
}
.navbar .nav-item .addpost.active path {
  stroke: var(--accent);
  stroke-width: 20px;
  fill: #ffff;
}

.navbar .nav-item .addpost:hover path {
  fill: #ffff;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.8em;
  padding-left: 1.8em;
  text-align: center;
}

.navbar-expand-lg .navbar-nav .iconarea {
  width: 40px;
  height: 40px;
}

.navbar .sidebartoogle:hover path {
  color: unset;
  stroke: unset;
  stroke-width: unset;
}

.navbar .sidebartoogle {
  padding: 0.5em;
  padding-left: 1.8em;
  padding-right: 0.1em;
}

.navbar .sidebartoogle:hover {
}
.connect2 {
  border-radius: 20px;
  background-color: #bbaeae;
  color: #0c0c0d;
  border-color: #8c8c8c;
  padding-left: 20px;
  padding-right: 20px;
}

.connect2:hover {
  border-radius: 20px;
  background-color: #bbaeae;
  color: #0c0c0d;
  border-color: #8c8c8c;
  padding-left: 20px;
  padding-right: 20px;
}
.navbar-nav {
  flex-direction: row;
}
@media screen and (max-width: 576px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1em;
    padding-left: 1em;
  }
  .navbar-brand {
    display: none;
  }
  .navbar-nav {
    flex-direction: column;
  }
  .navbar-nav .nav-item {
    margin-bottom: 20px;
  }
  .navbar .dropdown .dropdown-toggle {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-nav .iconarea {
    width: 20px;
    height: 20px;
  }
  .navbar .apptitle {
    font-size: 0.8em;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }

  .navbar .rounded-circle {
    height: 25px;
    width: 25px;
    line-height: 0px;
    border: 3px solid #aab7ff;

    padding: 4px;
  }
  #navibar .navbar .plus {
    width: 10px;
    height: 10px;
    line-height: 10px;
  }
  .navbar .sidebartoogle {
    padding-left: 0.7em;
    color: dimgrey;
  }
  .navbar-light .navbar-nav .nav-link.active {
    border-bottom: 3px solid #aab7ff;
  }
}

@media screen and (max-width: 576px) {
  .navbar .apptitle img {
    width: 40px;
    height: 40px;
  }
  .navbar {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }
}

@media screen and (max-width: 768px) {
}

.connect {
  border-radius: 20px;
  background-color: #fff;
  color: #429bee;
}
.connect:hover {
  background-color: #fff;
  color: #429bee;
}
