
.sharesomethingcard {
  width: 99%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow:0px 0px 5px 1px rgb(252 169 173 / 85%);
  position: relative;
  height: 110px;
  cursor: pointer;
}

.sharesomethingcard .sharesomethingcontent{
  margin: 15px;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.sharesomethingcard .dpcircle img{
 width: 100%;
 height: 100%;
 cursor: pointer;
}
.sharesomethingcard .dpcircle{
  position: absolute;;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50%;
  cursor: pointer;
  transform: translate(0%, -50%);
  margin-top: -10px;

}
.sharesomethingcard .postform{
  margin-left: 60px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  text-align: left;
  margin-top: -10px;
  cursor: pointer;
}

.sharesomethingcard .privacyselect{

  margin-top: -20px;
}
.sharesomethingcard .postform .privacyselecttext{
  font-weight: 300;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

.sharesomethingcard .postform .posttext{
  color: #828282;
  font-weight: 300;
  margin-top: 20px;
  font-size: 14px;
}
/* .sharesomethingcard .postform {
width: 98%;
}
 */
.sharesomethingcard .posttext{
  top: 45px;
    position: absolute;
  left: 60px;
}
.sharesomethingcard .shareform,.sharesomethingcard textarea.form-control, .sharesomethingcard .form-group{
  height: 100%;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 10px;
}

.sharesomethingcard .sharetextbox{
  border: 0px;
  resize:none;
}

@media screen and (max-width: 700px) {

.sharesomethingcard .postform {

  width: 80% !important;
}
}